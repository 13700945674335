<template>
  <div class="pb ">
 
    <div class="headerow">

      <h1>
        {{ $t('growers_view_title') }} 
      </h1>

      <GeneralSearchShort
        @search="changeKeyword"
        :val="keyword"
        :placeholder="$t('growers_search_placeholder')"
        />  

      <UiSort 
        @choose="sort = $event"
        :val="'diaries'"    
        :options="[
          {id:'diaries', name: $t('universal_sort_diaries')},    
          {id:'likes', name: $t('universal_sort_likes2')},        
          {id:'reg', name: $t('universal_sort_signup_date')},      
          {id:'visit', name: $t('universal_sort_last_visited')},      
        ]"
        />
      
    </div>
 

      
    <div 
      v-if="growers_data?.items.length"
      class="growers_boxs results two_column">
      <GeneralGrower 
        v-for="(opt,ind) in growers_data.items"
        :key="ind"  
        :num="ind+1"  
        :data="opt"      
        >       
      </GeneralGrower>

      <client-only>
        <InfinityScroll 
          :is-loading="is_loading"
          ref="el_infinity_scroll"/>
      </client-only>
    </div>

    <SkGrowerRows
      v-else-if="is_loading && !growers_data?.items.length"
      />

    <UiEmpty
      v-else
      />


  

  </div>
</template>

<script setup>


import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'
import InfinityScroll from '~/components/InfinityScroll.vue';


const start = ref(0)
const limit = ref(20)
const is_ended = ref(false)
const {$api, $adv, $tagsUtil, $ga} = useNuxtApp()
const el_infinity_scroll = ref(null)
const sort = ref('diaries')
const keyword = ref('')



const changeKeyword = function(text) {  
  start.value = 0;
  keyword.value = text;
}  

const loadData = async function() {     
  const response = await $api.getGrowers({
    keyword: keyword.value,
    sortable: sort.value,
    start: start.value,
    limit: limit.value,
  })  
  return response;  
}



const { pending: is_loading,  data: growers_data } = await useLazyAsyncData('growers_data', async () => await loadData())



watchArray([start, sort, keyword], async ([new_start, new_sort, keyword], added, removed) => {
  console.log('watcher');

  if(new_start == 0){
    growers_data.value.items = [];
    is_ended.value = false;
  }
  
  is_loading.value = true;

  var dt = await loadData();

      
  if(dt?.items.length == 0)
    is_ended.value = true;
  
  growers_data.value.items = [...growers_data.value.items, ...dt.items]  

  is_loading.value = false;

  $adv.apply();

}, {deep: true})



useIntersectionObserver(
  el_infinity_scroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(!is_loading.value && !is_ended.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)

useHead(useNuxtApp().$head.getGrowerList());


</script>

<style scoped>

h1{
  margin-right: auto;
}

.add_filter_box{
  display: flex;
  margin-left: auto;
}

.headerow{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

@container pb (max-width: 700px) {
  .headerow{

  }
  .headerow > .search{
    order: 1;
    width: 100%;
  }
  .headerow > .stags{
    order: 0;
    margin: 0;
  }
  .headerow > .sort{
    order: 2;
    justify-content: flex-end;
    width: 100%;
    margin: 0;
  }
}



@media (min-width: 1100px) {
  .growers_boxs{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
  }
  
}

@media (min-width: 1350px) {
  
}

/* WITH SIDEBAR */


@media (min-width: 1100px) {
  .onnav .growers_boxs{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
  } 
}
 


@media (max-width: 768px) {
  .growers_boxs{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
  } 
}
 

</style>
